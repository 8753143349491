:root {
    --bg-color: rgb(20, 20, 20);
    --card-color: rgb(23, 23, 23);
  }

  .projects {
    align-items: center;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    margin: 0px;
    padding: 0px;
  }

  #cards {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;  
    max-width: 916px;
    width: calc(100% - 20px);
  }

  #cards:hover > .card::after {
    opacity: 1;
  }

  .card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 260px;
    flex-direction: column;
    position: relative;
    width: 300px;  
  }

  .card:hover::before {
    opacity: 1;
  }

  .card::before,
  .card::after {
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0px;
    opacity: 0;
    position: absolute;
    top: 0px;
    transition: opacity 500ms;
    width: 100%;
  }
  
  .card::before {
    background: radial-gradient(
      800px circle at var(--mouse-x) var(--mouse-y), 
      rgba(255, 255, 255, 0.06),
      transparent 40%
    );
    z-index: 3;
  }
  
  .card::after {  
    background: radial-gradient(
      600px circle at var(--mouse-x) var(--mouse-y), 
      rgba(255, 255, 255, 0.4),
      transparent 40%
    );
    z-index: 1;
  }
  
  .card > .card-content {
    background-color: var(--card-color);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    inset: 1px;
    padding: 10px;
    position: absolute;
    z-index: 2;
  }
  h1, h2, h3, h4, span {
    color: rgb(240, 240, 240);
    font-family: Inter-regular;
    font-weight: 300;
    margin: 0px;
  }
  .card-column {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2px;
  }
  .card-info-wrapper {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 0px 20px;
  }
  .card-info-title > h3 {
    font-size: 1.1em;
    line-height: 20px;
  }
  
  .card-info-title > h4 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    margin-top: 20px;
    line-height: 22px;
  }
  /* -- ↓ ↓ ↓ some responsiveness ↓ ↓ ↓ -- */
  
  @media(max-width: 1000px) {
    body {
      align-items: flex-start;  
      overflow: auto;
    }
    
    #cards {    
      max-width: 1000px;
      padding: 10px 0px;
    }
    
    
      .card {
        flex-shrink: 1;
        width: calc(50% - 4px);
      }
    
  }
  
  @media(max-width: 500px) {
    .card {
      height: 180px;
      width: 100%;
    }
    #cards {
     margin-top: 50px;
     margin-bottom: 50px;
    }
    .card-info-wrapper {
      padding: 0px 10px;
    }
    .card-info > i { 
      font-size: 0.8em; 
    }
    .card-info-title > h3 {
      font-size: 0.9em;
    }
    .card-info-title > h4 {
      font-size: 0.8em;
      margin-top: 4px;
    }
  }
  @media(max-width: 430px) {
    #cards {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .card {
      width: 100%;
    }
  }
  
