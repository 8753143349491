:root {
    --color: white;
}
.contact-container {
    position: relative;
    min-height: 100vh;
}
.contact {
    scroll-snap-align: start;
    padding-top: 2%;
    .main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .notification .title,
    .message {
        font-family: sans-serif;
    }

    .section-header {
        text-align: center;
        margin: 0 auto;
        padding-top: 40px;
        font: 45px;
        font-family: sans-serif;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 6px;
    }

    .contact-form {
        display: flex;
        justify-content: center;
        width: 650px;
        position: relative;
        max-width: 100%;
        padding-top: 30px;
        padding-bottom: 2%;


        form {
            width: 100%;
            max-width: 100%;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .row-1 {
            display: flex;
            gap: 10px;
            flex-direction: row;

            input {
                height: 50px;
                width: 100%;
                max-width: 100%;
            }
        }

        input {
            padding-left: 15px;
            padding-right: 15px;
            font-family: sans-serif;
            font-size: 17px;
            margin-top: 10px;
            background-color: rgb(23, 23, 23);
            color: rgb(201, 195, 195);
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            max-width: 100%;
            height: 50px;
            border: 1px solid rgb(255, 255,255, 0.1);
            border-radius: 4px;

        }

        textarea {
            padding-left: 15px;
            padding-right: 15px;
            font-family: sans-serif;
            margin-top: 10px;
            background-color: rgb(23, 23, 23);
            border: none;
            font-size: 17px;
            padding-top: 10px;
            color: rgb(201, 195, 195);
            max-height: 200px;
            height: 200px;
            width: 100%;
            max-width: 100%;
            resize: none;
            box-sizing: border-box;
            border: 1px solid rgb(255, 255,255, 0.1);
    border-radius: 4px;
            
        }

        textarea:focus,
        input:focus {
            outline: none;
        }
    }
}

.form-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button.slide:hover,
    button.slide:focus {
        box-shadow: inset 10.5em 0 0 0 #{adjust-hue(white, 45deg)};
    }

    button {
        cursor: pointer;
        color: white;
        transition: 0.5s;
        height: 50px;
        width: 130px;
        margin-top: 20px;
        font-size: 15px;
        background: none;
        font-family: sans-serif;
        border: 2px solid white;
        border-radius: 4px;


        &:hover,
        &:focus {
            border-color: var(--color);
            color: black;
        }
    }
}
.bottom {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        font-size: 15px;
    }
}
a {
    color: white;
    text-decoration: underline;
}

@media screen and (max-width: 650px) {
    .contact-container {
        .contact {
            .contact-form {
                .row-1 {
                    flex-direction: column;
                    gap: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .contact-container {
        .contact {
            .contact-form {
                textarea {
                    height: 140px;
                }
            }
        }
    }
}