.main-menu {
    height: 100vh;
    position: absolute;
    right: 1%;
    display: flex;
    align-items: center;
}

.social-menu {
    display: block;
}

.social-menu li {
    list-style: none;
    padding-top: 15px;
    margin: 0 10px;
}

.social-menu li .fa {
    color: rgb(39, 39, 39);
    font-size: 25px;
    line-height: 50px;
    transition: .5s;
}

.social-menu li a {
    position: sticky;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 10px;

    background-color: white;
    text-align: center;
    transition: 0.5s;
    transform: translate(0, 0px);
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);
}

.social-menu li a:hover {
    transform: translate(-10px, 0);
}

@media screen and (max-width: 820px) {

    .main-menu {
        display: none;
    }
}
