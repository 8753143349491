* {
  margin: 0;
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
  url("./fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Inter-Bold";
    src: local("Inter-Bold"),
    url("./fonts/Inter-Bold.ttf") format("truetype")
}
body {
    background-color: rgb(20, 20, 20);
}
::selection {
  color: black;
  background: white;
}
