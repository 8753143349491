* {
  padding: 0;
  margin: 0;
}

.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  z-index: 100;
}
.navbar .left {
  margin-left: 30px;

}
.navbar .right {
  margin-right: 30px;

}
nav {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 40px;
    li a {  
      display: inline;  
      text-decoration: none; 
      color: rgba(255, 255, 255, 0.805);
      font-size: 20px;
      user-select: none; 
      font-family: 'Inter-regular';
      transition: 1s;
    }  
  }
  li a:hover {
    color: rgb(171, 166, 166);
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    display: none;
  }
}