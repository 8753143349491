.Home {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;

    .home-container {
        display: flex;
        padding-left: 80px;
        gap: 20px;
        padding-right: 12%;
        flex-direction: column;
    }
    .header {
        h4 {
            font-size: 16px;
            font-family: Inter-Regular;
            color: rgb(171, 166, 166);
            letter-spacing: 0.5px;
        }
    }
    .title {
        h1 {
            font-size: 55px;
            font-family: Inter-Regular;
            letter-spacing: 2px;
        }
        h1.secondary {
            opacity: 0.5;
        }
    }
    .footer {
        max-width: 700px;
        p {
            font-size: 18px;
            color: rgb(171, 166, 166);
            padding-top: 12px;
            font-family: Inter-Regular;
            line-height: 30px;
        }
    }
    .footer-btn {
        width: 100%;

        button.slide:hover,
        button.slide:focus {
            box-shadow: inset 10.5em 0 0 0 #{adjust-hue(white, 45deg)};
        }

        button {
            cursor: pointer;
            color: white;
            transition: 0.5s;
            letter-spacing: 1px;
            height: 50px;
            width: 130px;
            margin-top: 20px;
            font-size: 15px;
            background: none;
            font-family: sans-serif;
            border: 2px solid white;
            border-radius: 10px;


            &:hover,
            &:focus {
                border-color: var(--color);
                color: black;
            }
        }
    }
}

@media screen and (max-width: 1281px) {
    .Home {
        .home-container {
            padding-left: 6%;
    }
    }}

@media screen and (max-width: 600px) {
    .Home {
        .home-container {
            display: flex;
            padding-left: 6%;
            gap: 20px;
            padding-right: 10%;
            flex-direction: column;
        }
        .title {
            h1 {
                font-size: 40px;
            }
        }
    }
}
@media screen and (max-width: 430px) {
    .Home {
        .home-container {
            display: flex;
            padding-left: 6%;
            gap: 20px;
            padding-right: 10%;
            flex-direction: column;
        }
        .title {
            h1 {
                font-size: 35px;
            }
        }
        .footer {
            p {
                font-size: 17px;
            }
        }
    }
}
@media screen and (max-width: 380px) {
    .Home {

        .home-container {
            gap: 9px;
            padding-right: 0.5%;
        }
        .title {
            h1 {
                font-size: 27px;
            }
        }
        .footer {
            p {
                font-size: 17px;
            }
        }
    }
}

@media screen and (max-width: 280px) {
    .Home {

        .home-container {
            gap: 9px;
        }
        .title {
            h1 {
                font-size: 23px;
            }
        }
        .footer {
            p {
                font-size: 14px;
            }
        }
    }
}
